import CSelectSearch from '@/components/CSelectSearch'

export default {
  components: { CSelectSearch },
  data() {
    return {
      selectedUser: +this.$route.params.user_id || null,
      usersList: [],
      loadingUsers: false,
      ignoreSelectingUser: false,

      mountedComponent: false,
    }
  },
  watch: {
    selectedUser(userId) {
      if (this.ignoreSelectingUser) {
        this.ignoreSelectingUser = false
        return
      }

      if (!userId) return

      this.$router
        .push({
          name: 'CompanyUser',
          params: {
            id: this.$route.params.id,
            user_id: userId,
          },
        })
        .catch(() => {
          this.selectedUser = +this.$route.params.user_id || null
          this.ignoreSelectingUser = true
        })
    },
  },
  async mounted() {
    this.mountedComponent = true
    await this.fetchUsersList()
  },
  methods: {
    fetchUsersList() {
      this.loadingUsers = true
      this.$http.companies
        .getCompanyAllUsers({
          params: {
            per_page: -1,
            page: 1,
            sort_field: '',
            sort_direction: '',
            company_id: this.$route.params.id,
          },
        })
        .then(({ data }) => {
          this.usersList = data.data
            ?.map((el) => ({
              value: el.id,
              label: el.full_name,
            }))
            .sort((a, b) => {
              const _a = a.label?.toUpperCase()
              const _b = b.label?.toUpperCase()

              if (_a > _b) {
                return 1
              }
              if (_a < _b) {
                return -1
              }
              return 0
            })
        })
        .finally(() => {
          this.loadingUsers = false
        })
    },
  },
}
