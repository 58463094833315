<template>
  <div class="company-user">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'CompanyUsers' }">Users</router-link>
      </CBreadcrumbItem>
      <CBreadcrumbItem>
        <CSelectSearch
          v-model="selectedUser"
          class="company-user__select"
          :options="usersList"
          :search="true"
          :loading="loadingUsers"
        />
      </CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardHeader>
        <Navigation :items="topNavLinks" />
      </CCardHeader>
    </CCard>

    <router-view :user="user" :loading="loading" />
  </div>
</template>

<script>
import Navigation from '@/components/Card/Navigation'
import { getVerUri } from '@/services/ApiService'
import breadcrumbsMixin from './breadcrumbsMixin'
export default {
  name: 'Index',
  components: { Navigation },
  mixins: [breadcrumbsMixin],
  inject: ['mountedComponent'],
  data() {
    return {
      user: {},
      loading: false,
    }
  },
  computed: {
    topNavLinks() {
      const tasksLink =
        this.$route.name === 'CompanyUserTasksKanban'
          ? { name: 'Tasks', route: { name: 'CompanyUserTasksKanban' } }
          : {
              name: 'Tasks',
              route: { name: 'CompanyUserTasks' },
            }

      return [
        {
          name: 'General Info',
          route: { name: 'CompanyUserDetails' },
        },
        tasksLink,
        {
          name: 'Projects',
          route: { name: 'CompanyUserProjects' },
        },
      ]
    },
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.axios
        .get(getVerUri('users/users/' + this.$route.params.user_id))
        .then(({ data }) => {
          this.user = data.data
        })
        .catch()
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.company-user {
  &__select {
    width: 162px;
  }
}
</style>
